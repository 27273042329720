import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Image from '../components/Image'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Spotify from '../components/Spotify'
import Fade from 'react-reveal/Fade'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, body }) => (
  <main className="Home">
    <Helmet>
      <meta name="twitter:image" content={featuredImage} />
      <meta property="og:image:secure_url" content={featuredImage} />
      <meta property="og:image" content={featuredImage} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div className={`PageHeader relative`}>
      <Image
        background
        className="HomePage--BackgroundImage"
        resolutions="large"
        src={featuredImage}
        alt={title}
        size="cover"
      />
      <Fade top cascade>
        <div className="container relative">
          <div className="Content PageHeader--Subtitle">
            <p style={{ margin: '0' }}>
              Hello{' '}
              <span role="img" aria-label="wave">
                👋
              </span>
              , my name is
            </p>
          </div>
          <div>
            <h1 style={{ fontSize: '5rem', marginBottom: '0px' }}>
              Ilham Fadhil
            </h1>
          </div>
          <div style={{ maxWidth: '45%', fontSize: '1.1em' }}>
            <p>I develop full stack web apps and often write blogs.</p>
          </div>
          {subtitle && (
            <Content className="PageHeader--Subtitle" src={subtitle} />
          )}
        </div>
      </Fade>
    </div>
    <section className="section">
      <Spotify />
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
