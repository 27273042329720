import React from 'react'
import { SocialSpotify } from '@styled-icons/foundation'
import './Spotify.css'

const Spotify = () => {
  return (
    <div className="spotifyContainer">
      <a href="https://open.spotify.com/playlist/41CIgiAqKTi7dwBNbpwh2P?si=tihvipZuQiCXyUGSme30-Q">
        <SocialSpotify size="2em" /> Follow my spotify playlist!
      </a>
      <iframe
        title="Spotify Playlist"
        src="https://open.spotify.com/embed/playlist/41CIgiAqKTi7dwBNbpwh2P"
        width="300"
        height="425"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        style={{
          minWidth: '40vw',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '20px'
        }}
      ></iframe>
    </div>
  )
}

export default Spotify
